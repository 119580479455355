import type { ExtendedLocale } from '@/utils/env-utils';
import { getI18nLocales, getLocaleMapFromEnv } from '@/utils/env-utils';
import groupBy from 'lodash/groupBy';
import type { SettingsGroup, SiteParam } from '@/types/dto/siteParams';
import type {
  ISettingsCookies,
  ISettingsLogo,
  ISettingsAll,
  SettingsState,
  PhoneParam,
} from '@/types/store/settings';

const FLOMNI_CONFIG_FLAGS = ['displayHelpCustomer', 'returnToMessengers', 'displayChatOnly'];

const settings: SettingsState = {
  cookies: {
    btnText: '',
    text: '',
    title: '',
  },
  locales: getI18nLocales(getLocaleMapFromEnv(import.meta.env.VITE_LOCALE_CODE_NAME_MAP)),
  logo: {
    src: '/logo.svg',
  },
  all: {
    appLinkIos: null,
    appLinkAndroid: null,
    displayHelpCustomer: false,
    displayChatOnly: false,
    flomniConfig: null,
    returnToMessengers: false,
    enableMindbox: false,
    SSO_DISABLED: false,
  },
  phoneParams: [],
};

const convertArrayToObject = (array: { name: string; value: string }[]) => {
  return array.reduce((acc: Record<string, string>, { name, value }) => {
    acc[name] = value;

    return acc;
  }, {});
};

const getParsedFlomniConfig = (settings: string) => {
  try {
    return JSON.parse(settings);
  } catch {
    return null;
  }
};

const prepareSetting = (value?: string) => {
  return Boolean(Number(value));
};

const getFlomniConfig = (config: Record<string, string>) => {
  return {
    ...FLOMNI_CONFIG_FLAGS.reduce((acc: Record<string, boolean>, key) => {
      acc[key] = prepareSetting(config?.[key]);
      return acc;
    }, {}),
    flomniConfig: getParsedFlomniConfig(config.flomniConfig),
    flomniSettingsLC: null,
  };
};

const useSettings = () => {
  const init = (items: SiteParam[]) => {
    const groups = groupBy(items, 'group');

    (Object.keys(groups) as SettingsGroup[]).forEach((key) => {
      const converted = convertArrayToObject(groups[key]);

      if (key === 'phoneParams') {
        settings.phoneParams = JSON.parse(converted.phoneParams);
      } else if (key === 'all') {
        settings.all = {
          ...(converted as unknown as ISettingsAll),
          enableMindbox: prepareSetting(converted.enableMindbox),
          SSO_DISABLED: prepareSetting(converted.SSO_DISABLED),
          ...getFlomniConfig(converted),
        };
      } else {
        // TODO: написать типы нормально, сейчас не очень ПАМАГИТЕ
        settings[key] = converted as unknown as ISettingsCookies & ISettingsLogo;
      }
    });
  };

  function getSettingsGroup(key: 'all'): ISettingsAll;
  function getSettingsGroup(key: 'phoneParams'): PhoneParam[];
  function getSettingsGroup(key: 'logo'): ISettingsLogo;
  function getSettingsGroup(key: 'cookies'): ISettingsCookies;
  function getSettingsGroup(key: 'locales'): ExtendedLocale[];

  function getSettingsGroup(key: SettingsGroup | 'locales') {
    return settings[key];
  }

  const getShowLangSwitcher = () => {
    return getSettingsGroup('locales').length > 1;
  };

  return {
    init,
    getShowLangSwitcher,
    getSettingsGroup,
  };
};

export default useSettings;
